import SEO from '../components/core/meta/SEO';
import { Container } from '../components/core/layout/Layout';
import Heading from '../components/core/typography/Heading';
import React from 'react';
import CategorizedPartnersList from '../components/partials/CategorizedPartnersList/CategorizedPartnersList';
import 'twin.macro';

const PartnersPage = () => {
  return (
    <>
      <SEO title="I partner di UGO" url="/i-partner-di-ugo" />
      <Container tw="py-16">
        <Heading variant="h1" tw="mb-10">
          I partner di UGO
        </Heading>
        <p tw="text-paragraph mb-4">
          Anche se siamo una realtà giovane, nata da pochi anni, abbiamo
          lavorato per costruire una fitta rete di Partner.
        </p>
        <p tw="text-paragraph">
          Sono sia realtà radicate sul territorio, con cui lavoriamo in squadra
          per creare un’offerta di servizi integrata. Sia realtà virtuali e
          digitali con cui lavoriamo per creare sinergie e contenuti di valore
          per le persone che si fidano di noi.
        </p>
        <CategorizedPartnersList />
      </Container>
    </>
  );
};

export default PartnersPage;
